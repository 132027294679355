import s from "./PrivacyPolicy.module.css";
import mainS from "../../App.module.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import i18n from "../../i18n";

export default function PublicOffer() {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);

  return (
    <div className={`${s.main} ${mainS.mainDiv}`}>
      <Helmet>
        <title>{i18n.t('titles.offer')}</title>
      </Helmet>
      <Header />
      <div className={mainS.container}>
        <div className={s.title}>
          Публічна оферта про надання добровільної благодійної пожертви
        </div>
        <div className={s.text}>
          <p>
            Ця оферта, адресована невизначеному колу осіб – фізичним і юридичним
            особам – відвідувачам сайту в мережі Інтернет:  www.saveartua.com
            (далі – Сайт), далі іменовані «Благодійник», і є офіційною і
            публічною пропозицією БЛАГОДІЙНОЇ ОРГАНІЗАЦІЇ «БЛАГОДІЙНИЙ ФОНД
            «СЕЙВ АРТ ЮА» , далі  іменується «Фонд», в особі , в особі
            Виконавчого Директора Кривого Миколи Миколайовича, що діє на
            підставі Статуту, укласти договір благодійного добровільного
            пожертвування (далі – договір), предмет і істотні умови якого
            вказані нижче:
          </p>
          <h1>Терміни та визначення:</h1>
          <p>
            Акцепт – повне і безумовне прийняття Публічної оферти шляхом
            вчинення дій по здійсненню грошового переказу за допомогою платіжних
            форм і засобів розміщених на Сайті, а також шляхом перерахування
            грошових коштів на розрахунковий рахунок Фонду через установи
            банків. Моментом Акцепту є дата зарахування коштів на розрахунковий
            рахунок Фонду.
          </p>
          <p>Платіж – добровільна благодійна пожертва. </p>
          <p>
            Добровільна благодійна пожертва – здійсненний Благодійником грошовий
            переказ для досягнення цілей, завдань, напрямів та видів статутної
            діяльності Фонду відповідно до Договору та Закону України «Про
            благодійну діяльність та благодійні організації».
          </p>
          <h1>Предмет договору: </h1>
          <p>
            Предметом цього договору є безоплатна  передача Благодійником на
            користь Фонду грошових коштів для  досягнення цілей, завдань,
            напрямів та видів статутної діяльності Фонду.
          </p>
          <p>
            Акцепт Договору означає, що Благодійник згоден з усіма його
            положеннями, він повною мірою усвідомлює предмет Договору і мету
            збору пожертв.
          </p>
          <p>
            Договір укладається шляхом безумовного та повного приєднання
            Благодійника до цього Договору та прийняття всіх істотних умов
            Договору.
          </p>
          <p>
            Благодійник і Фонд, керуючись ст. 207, ч. 2 ст. 639, ст.ст. 641, 642
            Цивільного кодексу України, ст. 7 Закону України «Про благодійну
            діяльність та благодійні організації», погоджуються, що Договір
            вважається укладеним у письмовій формі без підписання письмового
            примірника Сторонами з моменту вчинення Благодійником дій,
            передбачених Договором, що свідчать про згоду дотримуватися умов
            Договору.
          </p>
          <p>
            Договір розміщено на сайті www.polinaraiko.com у вільному доступі та
            у спосіб, що забезпечує ознайомлення зі змістом цього Договору
            кожної особи, що звертається до Фонду.
          </p>
          <p>Благодійник не може запропонувати свої умови Договору.</p>
          <h1>Права і обов’язки Сторін:</h1>
          <p>Фонд має право:</p>
          <ul>
            <li>
              Отримувати добровільні благодійні пожертви і використовувати їх
              для досягнення цілей, завдань, напрямів та видів статутної
              діяльності Фонду відповідно до Договору та Закону України «Про
              благодійну діяльність та благодійні організації».
            </li>
            <li>
              За проханням Благодійника надавати звіт про отриману добровільну
              благодійну пожертву та її використання.
            </li>
          </ul>
          <p>Благодійник має право: </p>
          <ul>
            <li>
              Перерахувати добровільну благодійну пожертву на рахунок Фонду у
              спосіб зазначений у Договорі;
            </li>
            <li>
              Попросити  звіт з  використання добровільного благодійного
              пожертвування.
            </li>
          </ul>
          <h1>Місце проведення збору коштів: </h1>
          <p>
            Збір добровільних благодійних пожертв проводиться на території будь
            – якої з країн світу.
          </p>
          <h1>Термін збору коштів: </h1>
          <p>
            Збір добровільних благодійних пожертв триває до моменту ліквідації
            Фонду, якщо інший термін не буде визначено Фондом.
          </p>
          <h1>Порядок загального доступу до звітів Фонду</h1>
          <p>
            Доступ до звітів Фонду здійснюється шляхом розміщення їх на Сайті в
            рубриці «ПРОЕКТИ».
          </p>
          <p>
            Інша інформація може надаватися Фондом в порядку і в терміни,
            передбачені законодавством України.
          </p>
          <h1>
            Всі витрати по сплаті сум, пов’язаних з перерахуванням
            пожертвування, несе Благодійник.
          </h1>
          <h1>Інші умови</h1>
          <p>
            Благодійник самостійно визначає розмір добровільного благодійного
            пожертвування.
          </p>
          <p>Пожертва є добровільною та не підлягає поверненню. </p>
          <p>
            Благодійник несе відповідальність за достовірність інформації,
            зазначеної при перерахуванні Пожертви.
          </p>
          <p>
            З метою дотримання Закону України «Про захист персональних даних» і
            належного виконання умов Договору Благодійник  дає згоду на обробку
            персональних даних.
          </p>
          <p>
            До відносин між Благодійником  і Фондом застосовуються положення
            українського законодавства.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
