/* eslint @typescript-eslint/no-unused-vars: off */
/**
 * ua.culture.monuments.api.v1.TripApi
 * Auth Service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: ermakovsa03@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ArticleDTO,
    ArticleListDTO,
    Base64FileDTO,
    CoordinatesDTO,
    EmailDTO,
    HelpTypeDTO,
    MonumentDTO,
    MonumentLevelDTO,
    MonumentListDTO,
    MonumentOwnershipDTO,
    MonumentStatusDTO,
    MonumentTypeDTO,
    PersonHelpDTO,
    PhotoDTO,
    ReportedMonumentDTO,
} from "./model";

import { BaseAPI } from "./base";

export interface ApplicationApis {
    defaultApi: DefaultApiInterface;
}

/**
 * DefaultApi - object-oriented interface
 */
export interface DefaultApiInterface {
    /**
     *
     * @summary createArticle
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    createArticle(params?: {
        articleDTO: ArticleDTO;
    }): Promise<ArticleDTO>;
    /**
     *
     * @summary createMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    createMonument(params?: {
        monumentDTO: MonumentDTO;
    }): Promise<MonumentDTO>;
    /**
     *
     * @summary createReportedMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    createReportedMonument(params?: {
        reportedMonumentDTO: ReportedMonumentDTO;
    }): Promise<ReportedMonumentDTO>;
    /**
     *
     * @summary deleteArticle
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    deleteArticle(params?: {
        queryParams?: { id: string,  };
    }): Promise<void>;
    /**
     *
     * @summary deleteMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    deleteMonument(params?: {
        queryParams?: { id: string,  };
    }): Promise<void>;
    /**
     *
     * @summary deleteMonumentPhoto
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    deleteMonumentPhoto(params?: {
        queryParams?: { id: string,  };
    }): Promise<void>;
    /**
     *
     * @summary getArticle
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    getArticle(params?: {
        queryParams?: { url: string,  };
    }): Promise<ArticleDTO>;
    /**
     *
     * @summary getArticleList
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    getArticleList(params?: {
        queryParams?: { pageNumber: number, pageSize: number, language: string,  };
    }): Promise<ArticleListDTO>;
    /**
     *
     * @summary getMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    getMonument(params?: {
        queryParams?: { id: string, language?: string,  };
    }): Promise<MonumentDTO>;
    /**
     *
     * @summary getMonumentList
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    getMonumentList(params?: {
        queryParams?: { language?: string,  };
    }): Promise<MonumentListDTO>;
    /**
     *
     * @summary getReportedMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    getReportedMonument(params?: {
        queryParams?: { id: string,  };
    }): Promise<ReportedMonumentDTO>;
    /**
     *
     * @summary saveEmail
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    saveEmail(params?: {
        emailDTO: EmailDTO;
    }): Promise<void>;
    /**
     *
     * @summary savePersonHelp
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    savePersonHelp(params?: {
        personHelpDTO: PersonHelpDTO;
    }): Promise<void>;
    /**
     *
     * @summary updateArticle
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    updateArticle(params?: {
        articleDTO: ArticleDTO;
    }): Promise<ArticleDTO>;
    /**
     *
     * @summary updateMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    updateMonument(params?: {
        queryParams?: { language?: string,  };
        monumentDTO: MonumentDTO;
    }): Promise<MonumentDTO>;
    /**
     *
     * @summary uploadMonumentPhoto
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    uploadMonumentPhoto(params?: {
        queryParams?: { id: string, fileName: string,  };
        base64FileDTO?: Base64FileDTO;
    }): Promise<PhotoDTO>;
    /**
     *
     * @summary uploadReportedMonumentPhoto
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     * @memberof DefaultApi
     */
    uploadReportedMonumentPhoto(params?: {
        queryParams?: { id: string, fileName: string,  };
        base64FileDTO?: Base64FileDTO;
    }): Promise<PhotoDTO>;
}

/**
 * DefaultApi - object-oriented interface
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     *
     * @summary createArticle
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async createArticle(params: {
        articleDTO: ArticleDTO;
    }): Promise<ArticleDTO> {
        return await this.POST(
            "/article",
            {},
            { body: params.articleDTO, contentType: "application/json" }
        );
    }
    /**
     *
     * @summary createMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async createMonument(params: {
        monumentDTO: MonumentDTO;
    }): Promise<MonumentDTO> {
        return await this.POST(
            "/monument",
            {},
            { body: params.monumentDTO, contentType: "application/json" }
        );
    }
    /**
     *
     * @summary createReportedMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async createReportedMonument(params: {
        reportedMonumentDTO: ReportedMonumentDTO;
    }): Promise<ReportedMonumentDTO> {
        return await this.POST(
            "/reported-monument",
            {},
            { body: params.reportedMonumentDTO, contentType: "application/json" }
        );
    }
    /**
     *
     * @summary deleteArticle
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async deleteArticle(params: {
        queryParams?: { id: string,  };
    }): Promise<void> {
        return await this.DELETE(
            "/article",
            params && params.queryParams,
        );
    }
    /**
     *
     * @summary deleteMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async deleteMonument(params: {
        queryParams?: { id: string,  };
    }): Promise<void> {
        return await this.DELETE(
            "/monument",
            params && params.queryParams,
        );
    }
    /**
     *
     * @summary deleteMonumentPhoto
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async deleteMonumentPhoto(params: {
        queryParams?: { id: string,  };
    }): Promise<void> {
        return await this.DELETE(
            "/monument/photo",
            params && params.queryParams,
        );
    }
    /**
     *
     * @summary getArticle
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async getArticle(params: {
        queryParams?: { url: string,  };
    }): Promise<ArticleDTO> {
        return await this.GET(
            "/article",
            params && params.queryParams,
        );
    }
    /**
     *
     * @summary getArticleList
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async getArticleList(params: {
        queryParams?: { pageNumber: number, pageSize: number, language: string,  };
    }): Promise<ArticleListDTO> {
        return await this.GET(
            "/article/list",
            params && params.queryParams,
        );
    }
    /**
     *
     * @summary getMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async getMonument(params: {
        queryParams?: { id: string, language?: string,  };
    }): Promise<MonumentDTO> {
        return await this.GET(
            "/monument",
            params && params.queryParams,
        );
    }
    /**
     *
     * @summary getMonumentList
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async getMonumentList(params: {
        queryParams?: { language?: string,  };
    }): Promise<MonumentListDTO> {
        return await this.GET(
            "/monument/list",
            params && params.queryParams,
        );
    }
    /**
     *
     * @summary getReportedMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async getReportedMonument(params: {
        queryParams?: { id: string,  };
    }): Promise<ReportedMonumentDTO> {
        return await this.GET(
            "/reported-monument",
            params && params.queryParams,
        );
    }
    /**
     *
     * @summary saveEmail
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async saveEmail(params: {
        emailDTO: EmailDTO;
    }): Promise<void> {
        return await this.POST(
            "/email",
            {},
            { body: params.emailDTO, contentType: "application/json" }
        );
    }
    /**
     *
     * @summary savePersonHelp
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async savePersonHelp(params: {
        personHelpDTO: PersonHelpDTO;
    }): Promise<void> {
        return await this.POST(
            "/help",
            {},
            { body: params.personHelpDTO, contentType: "application/json" }
        );
    }
    /**
     *
     * @summary updateArticle
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async updateArticle(params: {
        articleDTO: ArticleDTO;
    }): Promise<ArticleDTO> {
        return await this.PUT(
            "/article",
            {},
            { body: params.articleDTO, contentType: "application/json" }
        );
    }
    /**
     *
     * @summary updateMonument
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async updateMonument(params: {
        queryParams?: { language?: string,  };
        monumentDTO: MonumentDTO;
    }): Promise<MonumentDTO> {
        return await this.PUT(
            "/monument",
            params && params.queryParams,
            { body: params.monumentDTO, contentType: "application/json" }
        );
    }
    /**
     *
     * @summary uploadMonumentPhoto
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async uploadMonumentPhoto(params: {
        queryParams?: { id: string, fileName: string,  };
        base64FileDTO?: Base64FileDTO;
    }): Promise<PhotoDTO> {
        return await this.POST(
            "/monument/photo",
            params && params.queryParams,
            { body: params.base64FileDTO, contentType: "application/json" }
        );
    }
    /**
     *
     * @summary uploadReportedMonumentPhoto
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async uploadReportedMonumentPhoto(params: {
        queryParams?: { id: string, fileName: string,  };
        base64FileDTO?: Base64FileDTO;
    }): Promise<PhotoDTO> {
        return await this.POST(
            "/reported-monument/photo",
            params && params.queryParams,
            { body: params.base64FileDTO, contentType: "application/json" }
        );
    }
}

export const servers: ApplicationApis[] = [
    {
        defaultApi: new DefaultApi("/"),
    },
];

